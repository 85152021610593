
import Vue from 'vue';
import Component from 'vue-class-component';
import {PropSync, Watch, Emit, Ref} from 'vue-property-decorator';
import qr from 'qrcode';

@Component
/**
 * Standard QR Code that parses a string and renders a QR Code image
 */
export default class QrCode extends Vue {
  @PropSync('input', {type: String}) readonly __PROPdata!: string
  @Ref() readonly el!: HTMLCanvasElement

  /**
   *
   */
  mounted() {
    this.generateCode();
  }

  /**
   * @param {[string]} s - render this string
   */
  @Watch('input')
  async generateCode(s?: string) {
    const data: string = s || this.__PROPdata;

    const qrElement = this.el;
    try {
      qr.toCanvas(qrElement,
          data,
          {},
          (error) => {
            if (error) {
              return this.__EMITerror('Failed To Generate QR Code');
            }
            this.__EMITready();
          },
      );
    } catch (error) {
      this.__EMITerror(error);
    }
  }

  /**
   * Fires when the QR Code is ready
   * @return {boolean}
   */
  @Emit('ready')
  __EMITready() {
    return true;
  }

  /**
   * Fires anytime there was an error
   *
   * @param {string} err
   * @return {string}
   */
  @Emit('error')
  __EMITerror(err:string) {
    return err;
  }
}
